export const COMMUNITY_USER_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment communityUserGenericPageHeaderFragment on CommunityUser {
    id
    uid
    firstName
    lastName
    suffix
    prefix
    employerName
    jobTitle
    profilePrivacyMode
    videoPresentationS3Url
    address {
      uid
      country {
        uid
        iso
        name
      }
    }
    __typename
    bannerFileResource {
      uid
      schemaCode
      path
    }
    pictureFileResource {
      uid
      schemaCode
      path
    }
    companyRoles(filter: {state: "ACCEPTED"}) {
      uid
      schemaCode
      company {
        uid
        name
       }
      defaultCompany
    }
    _connectionCount
    _sharedConnectionCount(myUid: "%authUser%")
    _isRecommendedForMe
    _followerCount
  }
`;
